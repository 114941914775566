import React, { Component, createRef } from "react";
import { createIntersectionObserver } from "../lib/intersection-observer";

export default class Highlightable extends Component {

  constructor (props) {
    super(props);
    this.state = {
      highlighted: false
    };
    this._highlightable = createRef();
  }

  highlight = () => {
    this.setState({ highlighted: true });
  };

  unhighlight = () => {
    this.setState({ highlighted: false });
  };

  componentDidMount () {
    this.inObserver = createIntersectionObserver(this._highlightable.current, {
      onEnterViewportScrollDown: this.highlight,
      onExitViewportScrollUp: this.unhighlight
    });
  }

  componentWillUnmount () {
    this.inObserver && this.inObserver.disconnect();
  }

  render () {
    const { children } = this.props;

    let clazz = "highlightable";
    if (this.state.highlighted) clazz += " highlighted";

    return (
      <span className={clazz} ref={this._highlightable}>
        <span>{children}</span>
      </span>
    );
  }
};
