const noop = () => {};

export function createIntersectionObserver (
  el,
  {
    onEnterViewportScrollDown = noop,
    onExitViewportScrollUp = noop
  }
) {
  const observer = new IntersectionObserver((entries) => {
    const entry = entries[0];
    if (entry) {
      if (entry.isIntersecting && entry.intersectionRect.top) {
        onEnterViewportScrollDown();
      } else if (entry.boundingClientRect.top > 0) {
        onExitViewportScrollUp();
      }
    }
  });

  observer.observe(el);

  return observer;
}
