import React, { Component } from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import Benefits from "../components/benefits";
import Highlightable from "../components/highlightable";
import AnimatedLines from "../components/animatedLines";
import SEO from "../components/seo";
import { isBrowser } from "react-device-detect";

export default class IngredientsPage extends Component {
  render () {
    const data = this.props.data;
    const ingredients = data.ingredients.edges.map(e => e.node).filter( i => i.title !== "Hemp Extract");
    ingredients.sort((a, b) => a.title.localeCompare(b.title))
    return (
      <Layout>
        <SEO title="Gluten Free - Vegan Certified Ingredients" description="Our CBD products are allergen and gluten-free. Explore the benefits of our high-quality ingredients used in our CBD products like aloe vera juice, hemp seed oil and more at Reason to Smile." />
        <section className="hero is-medium has-absolute-navbar">
          <div className="hero-body">

            <div className="container">
              <div className="columns is-centered">
                <div className="column is-6 has-text-centered">
                  <h3 className="has-text-weight-bold">
                    <Highlightable>Meet the team</Highlightable>
                  </h3>
                  <h1 style={{marginTop:"10px"}}>Our Ingredients</h1>
                  <h4 style={{marginTop:"10px"}}>We source only the highest-quality ingredients to bring you the best products on the market. Full transparency: they’re freakin’ awesome.</h4>
                </div>

              </div>
              <div className="columns is-centered is-multiline is-mobile">

                {ingredients.map(post => (
                  <div key={post.id} className="column is-3-desktop is-4-tablet is-6-mobile">
                    <Link to={`/ingredients${post.path}`}>
                      <div className="has-text-centered">
                        <img src={post.featured_media.source_url} alt={post.featured_media.alt_text || post.title} />
                        <h4 className="has-text-black">{post.title}</h4>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section className="section is-relative">
        <AnimatedLines className="lines-01" style={{
          transform: "scaleX(-1) rotate(80deg)",
          position: "absolute",
          top: "50%",
          left: isBrowser ? "-10%" : "-95%",
          zIndex: "1",
          width: "587px"
        }} />
        <AnimatedLines className="lines-02" style={{
          position: "absolute",
          transform: "scaleX(-1) rotate(-20deg)",
          top: "-80%",
          right: isBrowser ? "-8%" : "-95%",
          zIndex: "1",
          width: "610px"
        }} />
          <div className="container">

            <div className="columns is-centered is-multiline">

              <div className="column is-6 has-text-centered">
                <h1>The Smile Promise</h1>
                <h4 style={{marginTop:"20px"}}>From pure, ethically sourced ingredients to a responsible final product, Smile holds itself to the highest standard, in every way. Every Smile product is made from 100% organic hemp grown in the USA. No gimmicks, no additives, no BS—just the good stuff. We promise.</h4>
              </div>
            </div>
          </div>
        </section>
        <section className="section is-medium is-relative">
          <div className="container">
            <Benefits />
          </div>
        </section>
        <section className="section is-medium">

          <div className="container">
            <div className="columns is-vcentered">

              <div className="column is-relative happiness-inside-image" style={{
                backgroundImage: `url(https://reasontosmile.wpengine.com/wp-content/uploads/2020/01/WebsiteFinals-1.jpg)`
              }}/>
              <div className="column is-5 is-offset-1 has-text-centered-mobile">

                <h1  className="is-h1-desktop-mobile">
                  Happiness Inside
                </h1>
                <br/>
                <h4 className="">Smiles are contagious. Smiles have the power to change the world. Our CBD products are designed to help you tune into your best self, so you can be here & now… happily.</h4>
                <br/>
                <Link to="/story">

                  <button className="select-button is-primary">SEE OUR STORY</button>
                </Link>
              </div>

            </div>

          </div>

        </section>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
    query {
        ingredients: allWordpressPost(filter: {categories: {elemMatch: {name: {regex: "/Ingredients/i"}}}}) {
            edges {
                node {
                    id
                    title
                    slug
                    path
                    featured_media {
                        id
                        source_url
                        alt_text
                    }
                    categories {
                        name
                    }
                }
            }
        }
    }
`;
