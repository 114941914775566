import React from "react"

const Benefits = () => {

  return (
    <div className="columns is-centered is-multiline is-mobile">
      <div className="column is-3-mobile">
        <figure className="image is-square">
          <img data-src={require('../images/benefits/gf-allergen-free.svg')} className="lazyload" alt=""/>
        </figure>
      </div>
      <div className="column is-3-mobile">
        <figure className="image is-square">
          <img data-src={require('../images/benefits/vegan.svg')} className="lazyload" alt=""/>
        </figure>
      </div>
      <div className="column is-3-mobile">
        <figure className="image is-square">
          <img data-src={require('../images/benefits/non-gmo.svg')} className="lazyload" alt=""/>
        </figure>
      </div>
      <div className="column is-3-mobile">
        <figure className="image is-square">
          <img data-src={require('../images/benefits/hemp-from-usa.svg')} className="lazyload" alt=""/>
        </figure>
      </div>
      <div className="column is-3-mobile">
        <figure className="image is-square">
          <img data-src={require('../images/benefits/natural-flavoring.svg')} className="lazyload" alt=""/>
        </figure>
      </div>
      <div className="column is-3-mobile">
        <figure className="image is-square">
          <img data-src={require('../images/benefits/cgmp.svg')} className="lazyload" alt=""/>
        </figure>
      </div>
      <div className="column is-3-mobile">
        <figure className="image is-square">
          <img data-src={require('../images/benefits/zero-thc.svg')} className="lazyload" alt=""/>
        </figure>
      </div>
      <div className="column is-3-mobile">
        <figure className="image is-square">
          <img data-src={require('../images/benefits/cruelty-free.svg')} className="lazyload" alt=""/>
        </figure>
      </div>
    </div>
  )

}

export default Benefits
